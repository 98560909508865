import {FC, useMemo} from 'react';
import {Box, Stack} from '@mui/material';

import {APIItemRender} from 'components/DataList/types';
import {PublicationsIndexApiArg} from '@apiSchema/admin-api';
import {UsersAPIList, APIListNavigation} from 'components/DataList';

import {PublicationListItem} from './PublicationListItem';
import {PublicationsListFilter} from './PublicationsListFilter';
import {usePublicationsListQuery} from '../../utils';
import {useMyId} from 'utils';

const renderPublicationItem: APIItemRender = ({itemId}) => (itemId ? <PublicationListItem postId={itemId} /> : null);

export const PublicationsList: FC<{externalParams?: Partial<PublicationsIndexApiArg>}> = ({externalParams}) => {
  const myId = useMyId();
  const [{questId, sort, widgetId, tagId, isDeleted, isViewed, isCommented}] = usePublicationsListQuery();
  const query = useMemo(
    () =>
      Object.assign({} as PublicationsIndexApiArg, externalParams || {}, {
        sort,
        streamId: [],
        questId: questId || undefined,
        widgetId: widgetId ? [widgetId] : undefined,
        isDeleted: isDeleted && isDeleted !== 'none' ? isDeleted === 'deleted' : undefined,
        isViewedBy: isViewed === 'viewed' ? [myId] : undefined,
        isNotViewedBy: isViewed === 'not_viewed' ? [myId] : undefined,
        isCommentedBy: isCommented === 'commented' ? [myId] : undefined,
        isNotCommentedBy: isCommented === 'not_commented' ? [myId] : undefined,
        tagId: tagId?.filter(item => typeof item === 'string') || undefined,
      } as PublicationsIndexApiArg),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [questId, sort, JSON.stringify(tagId), JSON.stringify(externalParams), isDeleted, isViewed, isCommented, myId]
  );

  return (
    <Stack spacing={2}>
      <PublicationsListFilter />
      <Box sx={{p: 2, bgcolor: 'grey.5008', width: 1, flexShrink: 1, borderRadius: 1}}>
        <UsersAPIList
          spacing={2}
          params={query}
          api="adminApi"
          enableOverlayLoader
          endpoint="publicationsIndex"
          getTotal={res => res?.total}
          Header={APIListNavigation}
          renderItem={renderPublicationItem}
        />
      </Box>
    </Stack>
  );
};
